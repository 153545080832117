/* eslint-disable no-useless-return */
/* eslint-disable react/forbid-prop-types */
import PropTypes from 'prop-types';
import {
  useEffect, useState, useRef,
} from 'preact/hooks';
import { useForm } from 'react-hook-form';
import AppState from '@state';
import { Fragment } from 'preact';
import copy from 'copy-to-clipboard';

import {
  getWorkspaceLyrics, putWorkspaceLyrics, patchWorkspaceInstrumental, deleteWorkspaceLyrics,
} from '@api/restricted/workspace-lyrics-api';

import useBrowserDetect from '@hooks/useBrowserDetect';
import useErrorOverlay from '@hooks/useErrorOverlay';

import ToggleSwitch from '@ui-kit/inputs/toggleSwitch/ToggleSwitch';
import Spinner from '@ui-kit/loaders/Spinner';
import Box from '@ui-kit/box';
import Text from '@ui-kit/typography/text';
import Header from '@ui-kit/typography/header';
import BaseButton from '@ui-kit/buttons/baseButton';
import AlertMessage from '@ui-kit/alert/Alert';
import WorkspaceCard from '@distinct-components/cards/workspaceCard';
import BaseEditOverlay from '@layouts/full-screen/baseEditOverlay';
import CountDown from '@distinct-components/feedback/countDown';
import LinkButton from '@ui-kit/buttons/linkButton/LinkButton';
import Icon from '@ui-kit/icon';
import CopyIcon from '@assets/icons/copy-outline.svg';
import BaseEmptyState from '@distinct-components/feedback/baseEmptyState/BaseEmptyState';

import { COMPOSITION_EXPLANATIONS } from '@constants/supportingCopy';

import LyricItem from '@distinct-components/dataDisplay/lyricItem';

import {
  LyricViewWrapper,
  StyledOptionsRow,
  LyricEditorToolBar,
  LyricEditorWrapper,
  StyledTextArea,
} from './StructuredLyricsStyles';
import LyricSample from './sections/lyricSample';

const lineBreakRegex = /\r?\n/;
const paragraphBreakRegex = /(?:\r?\n){2,}/;
const tagRegex = /^\[#.*\]$/;
const badEndLineRegex = /[^\p{L}0-9?)"']+$/gu; // Allow ? ) " '

function StructuredLyrics({ workspaceId, activeUser }) {
  const [error, setError] = useState(null);
  const [markInstrumentalError, setMarkInstrumentalError] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [lyricChunks, setLyricChunks] = useState();
  const [isInstrumental, setIsInstrumental] = useState(false);
  const [isEditingLyrics, setIsEditingLyrics] = useState(false);
  const [resetExpanded, setResetExpanded] = useState(false);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [isClearingLyrics, setIsClearingLyrics] = useState(false);
  const [showSample, setShowSample] = useState(false);
  const [editingLyrics, setEditingLyrics] = useState();
  const lyricEditorRef = useRef(null);

  const {
    register, handleSubmit, setValue, getValues, formState: { isValid },
  } = useForm({ mode: 'onChange' });

  const browser = useBrowserDetect();

  const useToastAlert = (message) => {
    AppState.messages.toastMessage.value = { text: message, variant: 'success' };
  };

  const loadLyrics = async () => {
    try {
      const response = await getWorkspaceLyrics(workspaceId);
      if (response.status !== 200) {
        const json = await response.json();
        if (json.error) {
          useErrorOverlay({ errorMessage: `${json.error} when loading lyrics` });
        } else {
          useErrorOverlay({ errorMessage: `${response.statusText} when loading lyrics` });
        }
      } else {
        setError(null);
        setMarkInstrumentalError(null);
        const json = await response.json();
        if (json?.lyrics) {
          const chunks = json.lyrics.split(paragraphBreakRegex);
          setLyricChunks(chunks);
          setIsLoading(false);
        } else {
          setLyricChunks(null);
          setIsLoading(false);
        }
        setIsInstrumental(json?.instrumental);
        setIsLoading(false);
      }
      return null;
    } catch (err) {
      useErrorOverlay({ errorMessage: `${err.message} when loading lyrics` });
      return null;
    }
  };

  useEffect(() => {
    if (browser !== 'Firefox' || browser !== 'IE') {
      register('lyricsText', { required: true });
    }
    loadLyrics();
  }, []);

  const checkUpper = (str) => {
    const allLetters = str.replace(/[^\p{L}]/gu, ''); // Keep only letters
    const upperCase = allLetters.replace(/[^\p{Lu}]/gu, ''); // Keep only uppercase
    const diff = (upperCase.length / allLetters.length) * 100;
    return diff;
  };

  const handleMarkInstrumental = () => {
    setIsLoading(true);
    patchWorkspaceInstrumental(workspaceId, true)
      .then((response) => {
        if (response.status === 200) {
          loadLyrics().then(() => {
            setIsLoading(false);
            setMarkInstrumentalError('');
          });
        } else {
          response.json()
            .then((json) => setMarkInstrumentalError(json.error || response.statusText))
            .catch(() => setMarkInstrumentalError(response.statusText));
          setIsLoading(false);
        }
      })
      .catch((err) => {
        setIsLoading(false);
        setMarkInstrumentalError(err.message);
      });
  };

  const handleRemoveInstrumentalFlag = () => {
    setIsLoading(true);
    patchWorkspaceInstrumental(workspaceId, false)
      .then((response) => {
        setIsLoading(false);
        if (response.status === 200) {
          response.json().then(() => {
            loadLyrics().then(() => {
              setError('');
            });
          });
        } else {
          response.json().then((json) => {
            if (json.error) {
              setError(json.error);
            } else {
              setError(response.statusText);
            }
          });
        }
      })
      .catch((err) => {
        setIsLoading(false);
        setError(err.message);
      });
  };

  const onClickClearLyrics = () => {
    setShowDeleteConfirmation(true);
    setTimeout(() => {
      setShowDeleteConfirmation(false);
    }, 5000);
  };

  const handleClearLyrics = () => {
    setIsClearingLyrics(true);
    setShowDeleteConfirmation(false);

    deleteWorkspaceLyrics(workspaceId)
      .then((response) => {
        if (response.status === 200) {
          response.json().then(() => {
            loadLyrics().then(() => {
              setIsClearingLyrics(false);
              setError('');
              setIsEditingLyrics(false);
              AppState.workspace.isEditingChild.value = false;
            });
          });
        } else {
          response.json().then((json) => {
            if (json.error) {
              setError(json.error);
            } else {
              setError(response.statusText);
            }
          });
        }
      })
      .catch((err) => {
        setIsClearingLyrics(false);
        setError(err.message);
      });
  };

  const onToggleEditLyrics = () => {
    if (lyricChunks) {
      const lyrics = lyricChunks.join('\n\n');
      setValue('lyricsText', lyrics);
      setEditingLyrics(lyrics);
    } else {
      setValue('lyricsText', '');
    }
    setIsEditingLyrics(!isEditingLyrics);
    setShowSample(false);
    AppState.workspace.isEditingChild.value = !AppState.workspace.isEditingChild.value;
  };

  const lyricsCleanup = (lyricsText) => {
    const cleanedParagraphs = [];

    const paragraphs = lyricsText.trim().split(paragraphBreakRegex);
    paragraphs.forEach((paragraph) => {
      const cleanedLines = [];

      const lines = paragraph.split(lineBreakRegex);
      lines.forEach((l, index) => {
        let line = l.trim();
        if (index !== 0 || !tagRegex.test(line)) {
          line = line.replace(badEndLineRegex, '');
          if (checkUpper(line) > 90) {
            line = line.toLowerCase();
          }
          if (!line.startsWith('(')) {
            line = line.charAt(0).toUpperCase() + line.substring(1);
          } else {
            line = line.charAt(0) + line.charAt(1).toUpperCase() + line.substring(2);
          }
        }
        if (line) {
          cleanedLines.push(line);
        }
      });

      if (cleanedLines.length > 0) {
        cleanedParagraphs.push(cleanedLines.join('\n'));
      }
    });

    return cleanedParagraphs.join('\n\n');
  };

  const handleSaveLyrics = (data) => {
    setIsLoading(true);
    const lyrics = lyricsCleanup(data.lyricsText);

    if (lyrics) {
      putWorkspaceLyrics(null, workspaceId, lyrics, true)
        .then((response) => {
          setIsLoading(false);
          if (response.status === 200) {
            response.json().then(() => {
              loadLyrics().then(() => {
                setError('');
                setIsEditingLyrics(false);
                AppState.workspace.isEditingChild.value = false;
              });
            });
          } else {
            response.json()
              .then((json) => setError(json.error || response.statusText))
              .catch(() => setError(response.statusText));
            setIsLoading(false);
          }
        })
        .catch((err) => {
          setIsLoading(false);
          setError(err.message);
        });
    } else {
      setIsLoading(false);
      setError('Invalid lyrics');
    }
  };

  const handleChunkTag = (chunk, index) => {
    setIsLoading(true);
    lyricChunks[index] = chunk;
    const updatedLyrics = `${lyricChunks.join('\n\n')}`;

    putWorkspaceLyrics(null, workspaceId, updatedLyrics, false)
      .then((response) => {
        setIsLoading(false);
        if (response.status === 200) {
          response.json().then(() => {
            loadLyrics().then(() => {
              setError('');
            });
          });
        } else {
          response.json().then((json) => {
            if (json.error) {
              setError(json.error);
            } else {
              setError(response.statusText);
            }
          });
        }
      })
      .catch((err) => {
        setIsLoading(false);
        setError(err.message);
      });
  };

  const handleCopyToClipboard = () => {
    if (lyricChunks) {
      copy(lyricChunks.join('\n\n'));
      useToastAlert('Copied to Clipboard');
    }
  };

  const onShowSamples = (e) => {
    e.preventDefault();
    if (showSample) {
      const workingText = getValues('lyricsText');
      setEditingLyrics(workingText);
      setShowSample(false);
    } else {
      setShowSample(true);
    }
  };

  return (
    <Box display="flex">
      <WorkspaceCard
        title="Structured Lyrics"
        isCardLocked={!activeUser.isCanEdit || isInstrumental}
        lockedText={!activeUser.isCanEdit ? COMPOSITION_EXPLANATIONS.locks.manageSectionBlocked : COMPOSITION_EXPLANATIONS.locks.lyricsForInstrumentalBlocked}
        handleEditSection={onToggleEditLyrics}
        showCardActions={Boolean(lyricChunks)}
      >
        {isLoading ? <Box minHeight="286px"><Spinner className="anti-flicker-anim-1s" size="1.25em" variant="page" /></Box> : (
          <Box
            position="relative"
            style={{
              minHeight: '286px',
            }}
          >
            {lyricChunks
            && (
              <Box className="cursor-p" display="flex" alignItems="center" justifyContent="flex-end" position="absolute" right="0">
                <Icon size="1em" pt="3px" mb="0.25em" mr="0.5em"><CopyIcon /></Icon>
                <Text fontSize="0.75em" onClick={handleCopyToClipboard}>Copy to Clipboard</Text>
              </Box>
            )}

            {lyricChunks
              ? (
                <LyricViewWrapper pt="0.875em">
                  {lyricChunks.map((chunk, index) => (
                    <LyricItem
                      lyricChunk={chunk}
                      handleChunkTag={handleChunkTag}
                      index={index}
                      setResetExpanded={setResetExpanded}
                      resetExpanded={resetExpanded}
                      isLoading={isLoading}
                      activeUser={activeUser}
                    />
                  ))}
                </LyricViewWrapper>
              )
              : (
                <StyledOptionsRow>
                  {isInstrumental
                    ? (
                      <Box display="flex" justifyContent="center" width="100%">
                        <BaseEmptyState
                          message="This Composition was marked as Instrumental. It does not contain any lyrics"
                          secondaryAction={handleRemoveInstrumentalFlag}
                          showIllustration={false}
                          minHeight="16em"
                          secondaryActionText="Change and Add Lyrics"
                        />
                      </Box>
                    )
                    : (
                      <Box width="100%">
                        {markInstrumentalError && (
                        <AlertMessage
                          variant="negative"
                          message={markInstrumentalError}
                          mb="1.5em"
                        />
                        )}
                        {activeUser.isCanEdit
                      && (
                        <Box display="flex" justifyContent="center">
                          <BaseEmptyState
                            message="Want your lyrics to show up correctly on smart speakers and devices? Enter them in and follow our simple guidelines."
                            action={onToggleEditLyrics}
                            secondaryAction={handleMarkInstrumental}
                            showIllustration={false}
                            minHeight="16em"
                            actionText="Add Lyrics"
                            secondaryActionText="Mark as Instrumental"
                            mutedActions={false}
                          />
                        </Box>
                      )}
                      </Box>
                    )}
                </StyledOptionsRow>
              )}
          </Box>
        )}

      </WorkspaceCard>

      {isEditingLyrics
        && (
        <BaseEditOverlay closeFunction={onToggleEditLyrics} formWidth="46em">
          <Header fontSize="1.25rem" mb="0.5em">Edit Lyrics</Header>
          <Text>Type out each line exactly as you would hear it. Separate sections with a single line.</Text>
          <Box>
            <form id="lyricsForm" onSubmit={handleSubmit(handleSaveLyrics)} autoComplete="off">
              <LyricEditorToolBar>
                <Box>
                  <Text fontWeight="600" color="var(--white)">
                    {AppState.composition.name}
                  </Text>
                </Box>
                <Box display="flex">
                  <Text mr="0.75em" color="var(--white)">Guidelines</Text>
                  <ToggleSwitch id="sample">
                    <input
                      type="checkbox"
                      name="workspace"
                      disabled={isLoading}
                      id="sample"
                      checked={showSample}
                      onClick={(e) => onShowSamples(e)}
                    />
                  </ToggleSwitch>
                </Box>
              </LyricEditorToolBar>
              {showSample
                ? (
                  <LyricSample setShowSample={setShowSample} lyricEditorRef={lyricEditorRef} />
                )
                : (
                  <Box>
                    {browser === 'Firefox' || browser === 'IE'
                      ? (
                        <StyledTextArea
                          {...register('lyricsText', { required: true })}
                          placeholder="Enter Lyrics..."
                          type="textarea"
                          fluid
                          mb="1.5em"
                        />
                      )
                      : (
                        <LyricEditorWrapper
                          ref={lyricEditorRef}
                          autoFocus="true"
                          onInput={(e) => {
                            setValue('lyricsText', e.currentTarget.textContent, { shouldValidate: true });
                          }}
                          contentEditable="plaintext-only"
                        >
                          {editingLyrics && editingLyrics}
                        </LyricEditorWrapper>
                      )}
                  </Box>
                )}

              {error
                && (
                <AlertMessage
                  variant="negative"
                  message={error}
                  mb="1.5em"
                />
                )}

              <BaseButton
                mb="1.5em"
                type="submit"
                btnText="Save"
                disabled={!isValid}
                fluid
                isLoading={isLoading}
              />

            </form>

            {lyricChunks
            && (
            <Box mb="0.5rem" display="flex" alignItems="center" justifyContent="center">
              {showDeleteConfirmation
                ? (
                  <Fragment>
                    <CountDown mr="0.25em" timeInSeconds={5} size={12} color="var(--red-300)" />
                    <LinkButton btnText="Yes, Delete Lyrics" color="var(--red-300)" mr="1em" onClick={handleClearLyrics} />
                  </Fragment>
                )
                : <LinkButton type="button" color="var(--red-300)" spinnerVariant="danger" isLoading={isClearingLyrics} btnText="Delete Lyrics" onClick={onClickClearLyrics} />}
            </Box>
            )}

          </Box>

        </BaseEditOverlay>
        )}
    </Box>
  );
}

StructuredLyrics.propTypes = {
  workspaceId: PropTypes.number.isRequired,
  activeUser: PropTypes.object.isRequired,
};

export default StructuredLyrics;
